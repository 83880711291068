/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background: transparent;
  padding: 30px;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16 / 9; /* Maintains the aspect ratio */
}

.frame {
  position: relative;
  background: white;
  padding: 10px;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16 / 9; /* Maintains the aspect ratio */
}

.close-button {
  position: absolute;
  top: 1px;
  right: 1px;
  background: red;
  color: white;
  border: none;
  padding: 3px 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

/* .close-button:hover {
  background: darkred;
} */
