@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #010f33;
  color: #fff;
}

/* HEADER BACKGROUND */
.header_bg {
  background: 
  linear-gradient(
    0deg,
    #010f33 5.42%,
    rgba(1, 15, 51, 0) 50%,
    #010f33 95.08%
  ),
   linear-gradient(
    90deg,
    #010f33 5.42%,
    rgba(1, 15, 51, 0) 50%,
    #010f33 95.08%
  ),
    linear-gradient(0deg, rgba(1, 15, 51, 0.3) 0%, rgba(1, 15, 51, 0.3) 100%),
    url(./images/header_bg.png) rgb(37, 37, 37) 10.167px -145.198px / cover no-repeat;
}

@media screen and (min-width: 1024px) {
  .header_bg {
    background: linear-gradient(
      0deg,
      #010f33 5.42%,
      rgba(1, 15, 51, 0) 50%,
      #010f33 95.08%
    ),
     linear-gradient(
      90deg,
      #010f33 5.42%,
      rgba(1, 15, 51, 0) 50%,
      #010f33 95.08%
    ),
      linear-gradient(0deg, rgba(1, 15, 51, 0.3) 0%, rgba(1, 15, 51, 0.3) 100%),
      url(./images/header_bg.png) rgb(37, 37, 37) 70.167px -320.198px / 90.532% 181.065%
        no-repeat;
  }
}

.text_bg_clip {
  background: var(
    --Text-Gradiant,
    linear-gradient(270deg, #fbfdfe 0%, #e5e3e3 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_bg_clip {
  background: var(
    --Text-Gradiant,
    linear-gradient(270deg, #fbfdfe 0%, #e5e3e3 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_bg_roadmap {
  background: linear-gradient(295deg, #81e8ff 9.05%, #20b5d6 79.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ZERO TRUST POKER BACKGROUND */
.hacker_bg {
  background: linear-gradient(
      180deg,
      #010f33 1.44%,
      rgba(1, 15, 51, 0.72) 7.46%,
      rgba(1, 15, 51, 0.9) 54.66%,
      #010f33 100%
    ),
    url(./images/hacker_bg.png) lightgray 50% / cover no-repeat;
}

@media screen and (min-width: 1024px) {
  .hacker_bg {
    background: linear-gradient(
        180deg,
        #010f33 1.44%,
        rgba(1, 15, 51, 0.52) 7.46%,
        rgba(1, 15, 51, 0.7) 54.66%,
        #010f33 100%
      ),
      url(./images/hacker_bg.png) lightgray 50% / cover no-repeat;
  }
}

.flip-card {
  background-color: transparent;
  height: 420px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
